import { TransactionStatus } from "_constants";
import { firstLetterCapital } from "./first-letter-capital";

export const transactionsStatuses = (isAllIncluded = true, excludes = []) => {
  let transactionStatuses = [];
  if (isAllIncluded) {
    transactionStatuses.push({ label: "All", value: "all" });
  }
  for (const key in TransactionStatus) {
    if (
      TransactionStatus.hasOwnProperty(key) &&
      !excludes.includes(TransactionStatus[key])
    ) {
      transactionStatuses.push({
        label: firstLetterCapital(key),
        value: TransactionStatus[key],
      });
    }
  }

  return transactionStatuses;
};

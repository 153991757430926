import { useMutation, useQuery } from "@apollo/client";
import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import {
  CurrenciesDropdown,
  Loader,
  NoRecordFoundTable,
  UserBankAccountsDropdown,
} from "_components";
import {
  CREATE_INVOICE,
  CURRENCY_EXCHANGES,
  DASHBOARD_SALES_STATS,
} from "_queries/Affiliate";
import { endDateOfCurrentMonth, queryFilters } from "_utils";
import {
  DEFAULT_START_DATE,
  EXCHANGE_RATE_BUFFER,
  TransactionStatus,
} from "_constants";
import { toast } from "react-toastify";
import { invoiceNumber } from "_utils/invoice-number";

export const RequestPayment = () => {
  const [selectedBankAccounts, setSelectedBankAccounts] = useState({});
  const [withdrawals, setWithdrawals] = useState(null);
  const [exchangeRates, setExchangeRates] = useState([]);
  const [createInvoice, { loading: createInvoiceLoading }] = useMutation(
    CREATE_INVOICE,
    {
      errorPolicy: "all",
    }
  );

  const {
    data: fetchedWithdrawals,
    loading: loadingFetchedWithdrawals,
    refetch,
  } = useQuery(DASHBOARD_SALES_STATS, {
    fetchPolicy: "no-cache",
    variables: queryFilters({
      transactionDate: {
        start: DEFAULT_START_DATE,
        end: endDateOfCurrentMonth(),
      },
      status: TransactionStatus.AWAITING_PAYMENT,
    }),
  });
  const withdrawalsByCurrencies = fetchedWithdrawals?.dashboardSalesStats;
  const allCurrencies =
    withdrawalsByCurrencies && withdrawalsByCurrencies.map((x) => x.currency);

  const { loading } = useQuery(CURRENCY_EXCHANGES, {
    fetchPolicy: "no-cache",
    // The query will not execute until the salesByCurrencies exists
    skip: !withdrawalsByCurrencies,
    variables: {
      currencies: allCurrencies,
    },
    onCompleted: (result) => {
      setExchangeRates(result.currecyExchange);
    },
  });

  useEffect(() => {
    if (!loadingFetchedWithdrawals && fetchedWithdrawals) {
      const fetchedWithdrawalsMod = fetchedWithdrawals.dashboardSalesStats.map(
        (w) => {
          return {
            ...w,
            exchangeCurrency: w.currency,
            exchangeCurrencyValue: w.totalCommission,
          };
        }
      );
      setWithdrawals(fetchedWithdrawalsMod);
    }
  }, [fetchedWithdrawals, loadingFetchedWithdrawals]);

  const handleRowCurrencyDropdownChange = (rowId, selectedCurrency) => {
    const updatedWithdrawals = [...withdrawals];
    const currencyRow = updatedWithdrawals[rowId];
    let exchangeRateBuffer = null;

    const exchangeRate = exchangeRates
      .find((x) => x.base === currencyRow.currency)
      .rates.find((r) => r.currency === selectedCurrency).rate;

    if (currencyRow.currency !== selectedCurrency) {
      exchangeRateBuffer = +(exchangeRate - EXCHANGE_RATE_BUFFER).toFixed(6);
    } else {
      exchangeRateBuffer = exchangeRate;
    }
    const exchangeCurrencyValue =
      currencyRow.totalCommission * exchangeRateBuffer;

    //Updating property into the data
    updatedWithdrawals[rowId].exchangeCurrency = selectedCurrency;
    updatedWithdrawals[rowId].exchangeCurrencyValue =
      exchangeCurrencyValue.toFixed(2);

    // Update the state with the modified data
    setWithdrawals(updatedWithdrawals);
  };

  const handleRowBankAccountDropdownChange = (rowId, bankAccountId) => {
    setSelectedBankAccounts((prevSelectedBankAccounts) => ({
      ...prevSelectedBankAccounts,
      [rowId]: bankAccountId,
    }));
  };

  const handleRequestPayment = async (rowId) => {
    const bankAccountId = selectedBankAccounts[rowId];
    const fromCurrency = withdrawals[rowId].currency;

    const exchangeRateUSD = exchangeRates
      .find((x) => x.base === fromCurrency)
      .rates.find((r) => r.currency === "usd").rate;
    const exchangeCurrencyValueUSD =
      withdrawals[rowId].totalCommission * exchangeRateUSD;

    if (bankAccountId === undefined) {
      toast.error(`Please select the bank account.`);
      return false;
    }

    if (exchangeCurrencyValueUSD < 65) {
      toast.error(`Converted value should be above 65 USD.`);
      return false;
    }

    const response = await createInvoice({
      variables: {
        fromCurrency: withdrawals[rowId].currency,
        toCurrency: withdrawals[rowId].exchangeCurrency,
        bankAccountId,
      },
    });

    if (response.errors) {
      const { message } = response.errors.find(Boolean);
      toast.error(`${message}`);
      return false;
    }

    if (response.data) {
      refetch();
      const invoiceNo = response.data?.createInvoice?.invoiceNo;

      toast.success(
        `Request payment invoice ${invoiceNumber(
          invoiceNo
        )} has been created successfully. You can check the status in Invoice page.`
      );
    }
  };

  return (
    <Fragment>
      <Card>
        <CardHeader className="card-header border-0">
          <div className="d-flex align-items-center">
            <h5 className="card-title mb-0 flex-grow-1">Request Payment</h5>
            <div className="flex-shrink-0"></div>
          </div>
        </CardHeader>
        <CardBody>
          <div className="table-responsive table-card mb-4">
            {loading ? (
              <Loader />
            ) : (
              <Fragment>
                <table className="table align-middle table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="min-w-100px">Total Sales</th>
                      <th className="min-w-100px">Total Commission</th>
                      <th className="min-w-100px">Currency</th>
                      <th className="min-w-100px">Convert To</th>
                      <th className="min-w-100px">Converted Value</th>
                      <th className="min-w-100px">Account</th>
                      <th className="min-w-100px">Action</th>
                    </tr>
                  </thead>

                  <tbody className="list form-check-all">
                    {(withdrawals || []).map((singleWithdrawal, index) => (
                      <tr key={singleWithdrawal.currency}>
                        <td>{singleWithdrawal.totalSales}</td>
                        <td>{singleWithdrawal.totalCommission}</td>
                        <td>{singleWithdrawal.currency.toUpperCase()}</td>
                        <td>
                          <CurrenciesDropdown
                            defaultValue={singleWithdrawal.exchangeCurrency}
                            isClearable={false}
                            onCurrencyChange={(currency) => {
                              handleRowCurrencyDropdownChange(index, currency);
                            }}
                          />
                        </td>
                        <td>{singleWithdrawal.exchangeCurrencyValue}</td>
                        <td>
                          <UserBankAccountsDropdown
                            onBankAccountChange={(bankAccountId) => {
                              handleRowBankAccountDropdownChange(
                                index,
                                bankAccountId
                              );
                            }}
                          />
                        </td>
                        <td>
                          <button
                            className="btn btn-secondary"
                            onClick={() => handleRequestPayment(index)}
                            disabled={loading || createInvoiceLoading}
                          >
                            Request
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {withdrawals && withdrawals.length === 0 && (
                  <NoRecordFoundTable />
                )}
              </Fragment>
            )}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

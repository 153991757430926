import { DEFAULT_PER_PAGE } from "_constants";
import { monthStartEndDates } from "./month-start-end-dates";
import { transactionsStatuses } from "./transactions-statuses";

export const transactionsFilter = (currentPage, perPage, filters) => {
  let { startDate, endDate } = monthStartEndDates();
  let statuses = transactionsStatuses(false, []).map((x) => x.value);
  const filtersObj = {
    fields: [],
  };

  if (
    filters &&
    filters.transactionDate &&
    filters.transactionDate.start &&
    filters.transactionDate.end
  ) {
    const { start, end } = filters.transactionDate;
    startDate = start;
    endDate = end;
  }
  filtersObj.fields.push({
    field: "startDate",
    value: startDate,
    type: "normal",
  });
  filtersObj.fields.push({
    field: "endDate",
    value: endDate,
    type: "normal",
  });

  if (filters && filters.offer && filters.offer !== "all") {
    filtersObj.fields.push({
      field: "offer._id",
      value: filters.offer,
      type: "mongo",
    });
  }

  if (filters && filters.user && filters.user !== "all") {
    filtersObj.fields.push({
      field: "user._id",
      value: filters.user,
      type: "mongo",
    });
  }

  if (filters && filters.currency && filters.currency !== "all") {
    filtersObj.fields.push({
      field: "currency",
      value: filters.currency.toLowerCase(),
      type: "normal",
    });
  }

  if (filters && filters.platform && filters.platform !== "all") {
    filtersObj.fields.push({
      field: "platform",
      value: filters.platform.toLowerCase(),
      type: "normal",
    });
  }

  if (filters && filters.status && filters.status !== "all") {
    statuses = [filters.status];
    if (Array.isArray(filters.status)) {
      statuses = filters.status;
    }
  }
  filtersObj.fields.push({
    field: "status",
    value: JSON.stringify(statuses),
    type: "normal",
  });

  if (filters && filters.period) {
    filtersObj.fields.push({
      field: "period",
      value: filters.period,
      type: "normal",
    });
  }

  if (filters && filters.invoice_no) {
    filtersObj.fields.push({
      field: "invoice_no",
      value: filters.invoice_no,
      type: "normal",
    });
  }

  const currentPagePagination = currentPage ? currentPage : 1;
  const perPagePagination = perPage ? perPage : DEFAULT_PER_PAGE;
  const skip = (currentPagePagination - 1) * perPagePagination;
  const take = perPagePagination;
  const orderBy = { field: "transactionDate", value: "desc" };
  return {
    filters: filtersObj,
    pagination: { take, skip },
    orderBy,
  };
};

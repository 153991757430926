import { Col, Row } from "reactstrap";
import { Fragment, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { transactionsFilter } from "_utils";
import { Filters } from "./Filters";
import { PERFORMANCE_REPORT } from "_queries/Affiliate";
import { Listing } from "./Listing";
import { transactionsStatuses } from "_utils/transactions-statuses";
import { TransactionStatus } from "_constants";

export const PerformanceReport = () => {
  const tranStatuses = transactionsStatuses(false, [
    TransactionStatus.DECLINED,
  ]).map((x) => x.value);

  const [filters, setFilters] = useState({
    period: "by_day",
    status: tranStatuses,
  });

  const {
    data: performanceReportData,
    loading: performanceReportLoading,
    refetch,
  } = useQuery(PERFORMANCE_REPORT, {
    variables: transactionsFilter(null, null, filters),
  });

  useEffect(() => {
    refetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleFilterChange = (key, value) => {
    setFilters(() => {
      return {
        ...filters,
        [key]: value,
      };
    });
  };

  const filtersProps = {
    handleFilterChange,
  };

  const listingProps = {
    performanceReportData,
    performanceReportLoading,
    filters,
  };

  return (
    <Fragment>
      <Row id="filter">
        <Col lg={12}>
          <Filters {...filtersProps} />
        </Col>
      </Row>
      <Row id="listing">
        <Col lg={12}>
          <Listing {...listingProps} />
        </Col>
      </Row>
    </Fragment>
  );
};
